<template>
  <svg
    width="10"
    height="12"
    viewBox="0 0 10 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.36874 10.3264C9.56869 10.5645 9.53756 10.9189 9.29988 11.1188C9.19296 11.2077 9.0664 11.2499 8.93749 11.2499C8.77709 11.2499 8.6178 11.1818 8.50648 11.0492L4.99999 6.87408L1.49374 11.0483C1.38195 11.1819 1.22257 11.2499 1.06202 11.2499C0.934219 11.2499 0.806086 11.2066 0.700617 11.118C0.462961 10.9181 0.431789 10.5635 0.631758 10.3256L4.2664 5.99986L0.631828 1.67423C0.431883 1.43611 0.463008 1.08173 0.700687 0.881813C0.938039 0.681422 1.29312 0.712125 1.49327 0.950485L4.99999 5.12564L8.50624 0.951422C8.70656 0.713063 9.06101 0.682594 9.29866 0.882563C9.53632 1.08253 9.56749 1.43709 9.36752 1.67498L5.73359 5.99986L9.36874 10.3264Z"
      fill="currentColor"
    />
  </svg>
</template>
